<template>
  <div class="bt-forgot">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic vitae reiciendis, quo nulla quod eveniet tempora?
    Veritatis aliquid libero nostrum cumque ut voluptatibus. Veritatis reprehenderit commodi praesentium neque
    dignissimos aliquam?
  </div>
</template>

<script>
export default {
  name: 'Forgot'
}
</script>
